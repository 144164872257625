import React from 'react';

// Lazy load MUI components
export const LazyCssBaseline = React.lazy(() => import('@mui/material/CssBaseline'));
export const LazyPaper = React.lazy(() => import('@mui/material/Paper'));
export const LazyTypography = React.lazy(() => import('@mui/material/Typography'));
export const LazyTextField = React.lazy(() => import('@mui/material/TextField'));
export const LazyButton = React.lazy(() => import('@mui/material/Button'));
export const LazyLink = React.lazy(() => import('@mui/material/Link'));
export const LazyBox = React.lazy(() => import('@mui/material/Box'));
export const LazyAlert = React.lazy(() => import('@mui/material/Alert'));
export const LazyCircularProgress = React.lazy(() => import('@mui/material/CircularProgress'));
export const LazyDialog = React.lazy(() => import('@mui/material/Dialog'));
export const LazyDialogTitle = React.lazy(() => import('@mui/material/DialogTitle'));
export const LazyDialogContent = React.lazy(() => import('@mui/material/DialogContent'));
export const LazyDialogActions = React.lazy(() => import('@mui/material/DialogActions'));
export const LazyGrid = React.lazy(() => import('@mui/material/Grid'));
export const LazyTable = React.lazy(() => import('@mui/material/Table'));
export const LazyTableBody = React.lazy(() => import('@mui/material/TableBody'));
export const LazyTableCell = React.lazy(() => import('@mui/material/TableCell'));
export const LazyTableContainer = React.lazy(() => import('@mui/material/TableContainer'));
export const LazyTableHead = React.lazy(() => import('@mui/material/TableHead'));
export const LazyTableRow = React.lazy(() => import('@mui/material/TableRow'));

// Wrapper components with Suspense
const withSuspense = <T extends React.ComponentType<any>>(
  WrappedComponent: React.LazyExoticComponent<T>
) => {
  return (props: React.ComponentProps<T>) => (
    <React.Suspense fallback={<div style={{ minHeight: '24px' }} />}>
      <WrappedComponent {...props} />
    </React.Suspense>
  );
};

// Export wrapped components
export const CssBaseline = withSuspense(LazyCssBaseline);
export const Paper = withSuspense(LazyPaper);
export const Typography = withSuspense(LazyTypography);
export const TextField = withSuspense(LazyTextField);
export const Button = withSuspense(LazyButton);
export const Link = withSuspense(LazyLink);
export const Box = withSuspense(LazyBox);
export const Alert = withSuspense(LazyAlert);
export const CircularProgress = withSuspense(LazyCircularProgress);
export const Dialog = withSuspense(LazyDialog);
export const DialogTitle = withSuspense(LazyDialogTitle);
export const DialogContent = withSuspense(LazyDialogContent);
export const DialogActions = withSuspense(LazyDialogActions);
export const Grid = withSuspense(LazyGrid);
export const Table = withSuspense(LazyTable);
export const TableBody = withSuspense(LazyTableBody);
export const TableCell = withSuspense(LazyTableCell);
export const TableContainer = withSuspense(LazyTableContainer);
export const TableHead = withSuspense(LazyTableHead);
export const TableRow = withSuspense(LazyTableRow);
