import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LoadingSpinner } from '../components/common/LoadingSpinner';
import { ProtectedRoute } from '../components/common/ProtectedRoute';
import Layout from '../components/Layout';

// Lazy load components
const Login = React.lazy(() => import('../components/auth/Login'));
const Signup = React.lazy(() => import('../components/auth/Signup'));
const ForgotPassword = React.lazy(() => import('../components/auth/ForgotPassword'));
const Settings = React.lazy(() => import('../components/Settings'));
const ProposalsList = React.lazy(() => import('../components/proposals/ProposalsList'));
const ProposalForm = React.lazy(() => import('../components/proposals/ProposalForm'));
const InvoicesList = React.lazy(() => import('../components/invoices/InvoicesList'));
const InvoiceForm = React.lazy(() => import('../components/invoices/InvoiceForm'));

// Wrap lazy-loaded components with Suspense
const LazyComponent: React.FC<{ 
  component: React.LazyExoticComponent<React.ComponentType<any>> 
}> = ({ component: Component }) => (
  <Suspense fallback={<LoadingSpinner />}>
    <Component />
  </Suspense>
);

export const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<LazyComponent component={Login} />} />
      <Route path="/signup" element={<LazyComponent component={Signup} />} />
      <Route path="/forgot-password" element={<LazyComponent component={ForgotPassword} />} />
      
      {/* Protected Routes */}
      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <Layout>
              <Routes>
                <Route path="/" element={<Navigate to="/proposals" replace />} />
                <Route path="/proposals" element={<LazyComponent component={ProposalsList} />} />
                <Route path="/new-proposal" element={<LazyComponent component={ProposalForm} />} />
                <Route path="/proposal/:id" element={<LazyComponent component={ProposalForm} />} />
                <Route path="/invoices" element={<LazyComponent component={InvoicesList} />} />
                <Route path="/invoices/new" element={<LazyComponent component={InvoiceForm} />} />
                <Route path="/invoice/:id" element={<LazyComponent component={InvoiceForm} />} />
                <Route path="/settings" element={<LazyComponent component={Settings} />} />
              </Routes>
            </Layout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
