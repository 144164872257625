import { logEvent, setUserProperties } from '@firebase/analytics';
import { analytics } from '../firebase/config';
import { User } from 'firebase/auth';

export interface ErrorLogData {
  componentStack?: string;
  additionalContext?: string;
}

export const initializeAnalytics = (user: User | null) => {
  if (!analytics || !user) return;

  // Set user properties
  setUserProperties(analytics, {
    userRole: 'user',
    accountCreated: user.metadata.creationTime
  });
};

export const logError = (error: Error, data: ErrorLogData) => {
  if (!analytics) return;

  logEvent(analytics, 'error', {
    error_message: error.message,
    error_stack: error.stack,
    component_stack: data.componentStack,
    additional_context: data.additionalContext,
    timestamp: new Date().toISOString()
  });
};

export const logPageView = (pageName: string) => {
  if (!analytics) return;

  logEvent(analytics, 'page_view', {
    page_name: pageName,
    timestamp: new Date().toISOString()
  });
};

export const logUserAction = (actionName: string, actionData?: Record<string, any>) => {
  if (!analytics) return;

  logEvent(analytics, actionName, {
    ...actionData,
    timestamp: new Date().toISOString()
  });
};

// Custom events
export const logReportCreated = (reportId: string) => {
  logUserAction('report_created', { reportId });
};

export const logReportUpdated = (reportId: string) => {
  logUserAction('report_updated', { reportId });
};

export const logReportDeleted = (reportId: string) => {
  logUserAction('report_deleted', { reportId });
};

export const logPhotoUploaded = (reportId: string) => {
  logUserAction('photo_uploaded', { reportId });
};

export const logPdfGenerated = (reportId: string) => {
  logUserAction('pdf_generated', { reportId });
};
