import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { CssBaseline } from './components/mui';
import { AppRoutes } from './routes';
import { AuthProvider } from './contexts/AuthContext';
import { LoadingSpinner } from './components/common/LoadingSpinner';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    error: {
      main: '#f44336',
    }
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 14,
    h1: {
      fontSize: '24pt',
      fontWeight: 600,
    },
    h2: {
      fontSize: '20pt',
      fontWeight: 600,
    },
    h3: {
      fontSize: '16pt',
      fontWeight: 600,
    },
    h4: {
      fontSize: '14pt',
      fontWeight: 600,
    },
    h5: {
      fontSize: '12pt',
      fontWeight: 600,
    },
    h6: {
      fontSize: '11pt',
      fontWeight: 600,
    },
    body1: {
      fontSize: '11pt',
    },
    body2: {
      fontSize: '10pt',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@media print': {
          '@page': {
            size: 'A4 portrait',
            margin: 0,
          },
          body: {
            backgroundColor: '#ffffff',
          },
          '::-webkit-scrollbar': {
            display: 'none',
          },
        },
        '.pdf-document': {
          backgroundColor: '#f5f5f5',
          minHeight: '100%',
          padding: '20px',
        },
        '.pdf-section': {
          width: '210mm',
          minHeight: '297mm',
          padding: '25.4mm',
          margin: '0 auto 20px',
          backgroundColor: 'white',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          boxSizing: 'border-box',
          position: 'relative',
          '&.pdf-cover-page': {
            padding: 0,
            margin: 0,
            height: '297mm'
          }
        },
        '.pdf-photo-container': {
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '16px',
          marginBottom: '16px'
        },
        '.pdf-component-box': {
          backgroundColor: 'rgb(250, 250, 250)',
          padding: '24px',
          border: '1px solid rgb(227, 232, 240)',
          borderRadius: '8px',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: '4px',
            backgroundColor: '#1976d2',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px'
          }
        }
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '@media print': {
            margin: 0,
            boxShadow: 'none',
          }
        }
      }
    }
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Suspense fallback={<LoadingSpinner />}>
            <AppRoutes />
          </Suspense>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
